<template>
  <div class="main">
    <div style="min-height: 700px; padding-top: 20px; background: #fff">
      <div class="itemName">
        <el-divider>
          <div class="dividerFont">
            <span>营销服务</span>
            <div
              style="width: 60px; height: 2px; background-color: #029aff"
            ></div>
          </div>
        </el-divider>
      </div>
      <div class="content">
        <div class="left-tab">
          <div class="title">营销服务</div>
          <div
            :class="tabIndex == index ? 'active-tab' : 'list-tab'"
            v-for="(item, index) in configList"
            :key="index"
            @click="clickTab(index, item)"
          >
            {{ item.name }}
          </div>
        </div>
        <div class="right-info">
          <div class="info-title">营销服务介绍</div>
          <div class="html" v-html="info.content"></div>
          <div class="user-need">
            <div class="label">用户需求:</div>
            <el-input
              style="width: 70%"
              placeholder="请输入..."
              v-model="form.demand"
              type="textarea"
              show-word-limit
              :maxlength="500"
            ></el-input>
          </div>
          <div class="user-need">
            <div class="label label-text">
              <div class="span">附件</div>
              :
            </div>
            <el-upload
              ref="uploadImage"
              class="avatar-uploader"
              name="uploadfile"
              :action="$global.uploadAction"
              :on-success="handlePreview"
              :before-upload="beforeUpload"
              :on-remove="handleRemove"
              :on-error="upError"
               multiple
              :limit="6"
              list-type="picture"
            >
              <el-button type="primary">
                <img
                  class="upload-img"
                  src="@/assets/img/upload-icon.png"
                  alt=""
                />
                上传附件
              </el-button>
            </el-upload>
          </div>
          <div class="user-need">
            <div class="label">支付方式:</div>
            <div class="info-text" v-if="info.payment == '1'">工匠币</div>
            <div class="info-text" v-if="info.payment == '2'">权益</div>
          </div>
          <div class="user-need" v-if="info.payment == '1'">
            <div class="label">支付数量:</div>
            <div class="info-text">
              <div class="deduct-num">{{ info.coin }}个工匠币</div>
            </div>
          </div>
          <el-button type="primary" class="sub-btn" @click="submit">
            申请
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  jobAdditionalConfigList,
  jobAdditional,
} from "@/api/personalCenter/personalCenter";
export default {
  data() {
    return {
      form: {
        demand: "",
        file: [],
      },
      configList: [],
      tabIndex: 0,
      info: "",
    };
  },
  mounted() {
    this.infoList();
  },
  methods: {
    async infoList() {
      const res = await jobAdditionalConfigList({ userType: "1" });
      this.configList = res.data;
      this.info = res.data[0];
    },
    clickTab(val, item) {
      this.tabIndex = val;
      this.info = item;
      this.form.file = [];
      this.form.demand = "";
      this.$refs.uploadImage.clearFiles()
    },
    handlePreview(file){
      this.form.file.push(file.msg)
    },
    handleRemove(file,fileList){
      const data = [];
        fileList.map((item) => {
          data.push(item.response.msg);
        });
        this.form.file = data;
    },
     // 上传文件判断
    beforeUpload(file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 5;

      if (!isJPG) {
        this.$message.error("上传图片只能是 .jpg .png 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 5MB!");
      }
       return isJPG && isLt2M;
    },
    // 上传失败
    upError() {
      this.$message({
        type: "error",
        message: "上传失败",
        showClose: true,
        offset: 80,
      });
    },
    submit() {
      // if(!this.info.payment){
      //   this.$message.error('请填写需求信息')
      //   return
      // }
      // if(!this.info.file.length < 0){
      //   this.$message.error('请填写需求信息')
      //   return
      // }
      const obj = {
        targetId: localStorage.getItem("userId"),
        userType: "1",
        demand: this.form.demand,
        file: this.form.file.join(","),
        configId: this.info.id,
        payment: this.info.payment,
        jobVipEquityId: this.info.jobVipEquityId
      };
      if (this.info.payment === "1") {
        obj.coin = this.info.coin;
      }
      jobAdditional(obj)
        .then((res) => {
          if (res.success) {
             this.$message.success('申请成功')
          }else{
            this.$message.warning(res.message)
          }
        })
        .catch((error) => {
           this.$message.error('申请失败')
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
  min-height: 100vh;
  background-color: #fff;
  box-sizing: border-box;
  padding: 30px 17px;
  display: flex;
  justify-content: space-between;
  color: #333;
  position: relative;
  .left-tab {
    width: 20%;
    background-color: rgba(42, 130, 228, 0.09);
    box-sizing: border-box;
    padding: 15px;
    border-radius: 8px;
    background-image: url(~@/assets/img/back-img.png);
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: 77% 28%;
    .active-tab,
    .list-tab {
      margin: 12px 0;
      margin-left: 15px;
      width: 100%;
      box-sizing: border-box;
      padding: 2px 0;
      cursor: pointer;
    }
    .list-tab {
      color: rgba(42, 130, 228, 0.4);
    }
    .active-tab {
      color: #2a82e4;
      border-right: 3px solid #2a82e4;
    }
  }
  .right-info {
    width: 75%;
    .info-title {
      font-size: 16px;
      font-weight: 500;
      width: 100%;
      background-color: rgba(42, 130, 228, 0.09);
      color: #2a82e4;
      box-sizing: border-box;
      padding: 15px 0 13px 21px;
      border-radius: 5px;
      margin-bottom: 30px;
    }
    .html {
      margin: 0 auto;
      width: 90%;
      color: #2a82e4;
      margin-bottom: 20px;
    }
    .user-need {
      display: flex;
      flex-wrap: nowrap;
      margin-bottom: 20px;
      .label {
        width: 70px;
        margin-right: 20px;
      }
      .label-text {
        display: flex;
        // align-items: center;
        .span {
          width: 60px;
          text-align-last: justify;
        }
      }
      .info-text {
        color: #9ba2ab;
      }
      .deduct-num {
        font-size: 16px;
        color: #e6551c;
      }
      .deduct-del {
        font-size: 12px;
        color: #e61c1c;
      }
    }
  }
  .sub-btn {
    position: absolute;
    right: 2%;
    bottom: 5%;
  }
  .avatar-uploader {
    width: 200px;
    .upload-img {
      width: 10px;
      height: 14px;
      margin-right: 7px;
    }
  }
}
.itemName {
  padding: 0 86px;
  padding-bottom: 22px;
  .dividerFont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 50px;
    span {
      font-size: 24px;
      margin-bottom: 6px;
    }
  }
}
</style>
